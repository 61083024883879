import { upperCase } from "lodash";

export function sortByProperty (array, property, ascending = true) {
  if (property.indexOf ('.') !== -1) {
    return sortByNestedProperty (array, property, ascending);
  } else {
    return array.sort ((a, b) => (
      (upperCase(a[property]) < upperCase(b[property])) ? ((ascending) ? -1 : 1) :
        (upperCase(a[property]) > upperCase(b[property])) ? ((ascending) ? 1 : -1) : 0
    ));
  }
}

function sortByNestedProperty (array, property, ascending) {
  const list = property.split ('.');
  return array.sort ((a, b) => {
    const aa = list.reduce ((current, name) => current[name], a);
    const bb = list.reduce ((current, name) => current[name], b);
    return ((aa < bb) ? ((ascending) ? -1 : 1) : (aa > bb) ? ((ascending) ? 1 : -1) : 0);
  });
}

export function sortByProperties (array, properties, ascendings = []) {
  if (properties.reduce ((result, current) => (result || current.indexOf ('.') !== -1), false)) {
    return sortByNestedProperties (array, properties, ascendings);
  } else {
    return array.sort ((a, b) => {
      let order = 0;
      let index = 0;
      while ((order === 0) && (index < properties.length)) {
        const property = properties[index];
        const ascending = (typeof ascendings[index] === 'undefined') ? true : ascendings[index];
        order = ((a[property] < b[property]) ? ((ascending) ? -1 : 1) :
          (a[property] > b[property]) ? ((ascending) ? 1 : -1) : 0);
        index += 1;
      }
      return order;
    });
  }
}

function sortByNestedProperties (array, properties, ascendings) {
  const list = properties.map ((a) => a.split ('.'));
  return array.sort ((a, b) => {
    let order = 0;
    let index = 0;
    while ((order === 0) && (index < properties.length)) {
      const aa = list[index].reduce ((current, name) => current[name], a);
      const bb = list[index].reduce ((current, name) => current[name], b);
      const ascending = (typeof ascendings[index] === 'undefined') ? true : ascendings[index];
      order = ((aa < bb) ? ((ascending) ? -1 : 1) : (aa > bb) ? ((ascending) ? 1 : -1) : 0);
      index += 1;
    }
    return order;
  });
}
