import { Box, Button, Divider, Modal, Grid, Typography } from '@mui/material';
import React, { createContext, FC, useContext, useReducer } from 'react';
import { NotificationStatus } from '../../enums/notification-status.enum';
import InfoIcon from '@mui/icons-material/Info';
import { NotificationReason } from '../../enums/global/notification-reason.enum';


export interface INotificationState {
  // TODO figure out if the id should always be present, or if it is optional
  id?: number;
  open: boolean;
  onClose: (val: any, reason?: string) => void;
  title: string;
  message?: string | JSX.Element;
  component?: any;
  removeCancelBtn?: boolean;
  customBtnText?: string;
  customBtnFunc?: any;
  customComponent?: any;
  type: 'ERROR' | 'NOTIFICATION';
  toggleNotification?: (val: boolean) => void;
  reason?: string;
}

export interface INotificationReducer {
  type: NotificationStatus.UPDATE | NotificationStatus.RESET;
  data: Partial<INotificationState>;
}

interface IShowAlert {
  title: string;
  message?: string;
  severity?: 'error' | 'warning' | 'info' | 'success';
}

interface IMessagingContext {
  notificationState: INotificationState,
  notificationDispatch: (val: INotificationReducer) => void,
  alertState: IShowAlertState,
  alertDispatch: (val: IAlertReducer) => void;
  resetAlert: () => void;
  resetNotification: () => void;
}

interface IShowAlertState extends IShowAlert {
  showAlert: (val: IShowAlert) => void;
  showError: (val: Omit<IShowAlert, 'severity'>) => void;
  showWarning: (val: Omit<IShowAlert, 'severity'>) => void;
  showInfo: (val: Omit<IShowAlert, 'severity'>) => void;
  showSuccess: (val: Omit<IShowAlert, 'severity'>) => void;
}

interface IAlertReducer {
  type: 'UPDATE' | 'RESET',
  data: Partial<IShowAlert>
}

const defaultNotificationState: INotificationState = {
  open: false,
  title: 'Information',
  type: 'NOTIFICATION',
  onClose: (val) => { },
  toggleNotification: (val: boolean) => { }
}

const defaultAlertState = {
  title: '',
  message: ''
};

const MessagingContext = createContext<IMessagingContext>({
  notificationState: {} as INotificationState,
  notificationDispatch: (val: INotificationReducer) => { },
  alertState: {} as IShowAlertState,
  alertDispatch: (val: IAlertReducer) => { },
  resetAlert: () => { },
  resetNotification: () => { }
});

const notificationReducer = (state: INotificationState, { type, data }: INotificationReducer) => {
  switch (type) {
    case NotificationStatus.UPDATE:
      return { ...state, ...data };
    case NotificationStatus.RESET:
      return defaultNotificationState;
    default:
      throw new Error(`Type ${type} in reducer not defined`);
  };
}

const alertReducer = (state: IShowAlert, { type, data }: IAlertReducer) => {
  switch (type) {
    case 'UPDATE':
      return { ...state, ...data };
    case 'RESET':
      return defaultAlertState;
    default:
      throw new Error(`Type ${type} in reducer is not defined `);
  }
}



export const NotificationProvider: FC = ({ children }) => {
  const [_notificationState, notificationDispatch] = useReducer(notificationReducer, defaultNotificationState);

  const notificationState: INotificationState = {
    ..._notificationState,
    toggleNotification: (open = false) => notificationDispatch({ type: NotificationStatus.UPDATE, data: { open } }),
  }

  const [_alertState, alertDispatch] = useReducer(alertReducer, defaultAlertState);

  const alertState = {
    ..._alertState,
    showAlert: ({ title, message, severity }: IShowAlert) => alertDispatch({ type: 'UPDATE', data: { title, message, severity } }),
    showError: ({ title, message }: Omit<IShowAlert, 'severity'>) => alertDispatch({ type: 'UPDATE', data: { title, message, severity: 'error' } }),
    showWarning: ({ title, message }: Omit<IShowAlert, 'severity'>) => alertDispatch({ type: 'UPDATE', data: { title, message, severity: 'warning' } }),
    showInfo: ({ title, message }: Omit<IShowAlert, 'severity'>) => alertDispatch({ type: 'UPDATE', data: { title, message, severity: 'info' } }),
    showSuccess: ({ title, message }: Omit<IShowAlert, 'severity'>) => alertDispatch({ type: 'UPDATE', data: { title, message, severity: 'success' } }),
  }

  const resetAlert = () => alertDispatch({ type: 'RESET', data: {} });
  const resetNotification = () => notificationDispatch({ type: NotificationStatus.RESET, data: {} });

  const messagingState: IMessagingContext = {
    notificationState,
    notificationDispatch,
    alertState,
    alertDispatch,
    resetAlert,
    resetNotification
  }
  return (
    <MessagingContext.Provider value={messagingState}>
      {children}
    </MessagingContext.Provider>
  )
}

export const useMessagingContext = () => useContext(MessagingContext);

/**
 *
 * @description
 * This should only be imported one time into the application, all other functionality
 * is handled by useContext
 */

export const Notification: FC = () => {

  const { notificationState, resetNotification } = useMessagingContext();
  const { open, title, message, component, removeCancelBtn, customBtnText, customBtnFunc, customComponent, id, reason } = notificationState;

  const paper = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    maxWidth: 600,
    backgroundColor: '#FFFFFF',
    boxShadow: 24,
    '@media (max-width: 900px)': {
      top: '8em',
      left: 'auto',
      margin: 'auto',
      transform: 'none',
    },
  }

  const border = {
    borderTop: '1px solid #979797',
    width: '100%',
  }

  const infoIconStyle = {
    color: "#fabc55",
    verticalAlign: 'sub'
  }

  const handleClose = (val: boolean, reason?: string) => {
    if (!!customBtnFunc && val && id) {
      customBtnFunc(id)
      notificationState.onClose(val, reason);
      // Cleanup state when we are done with the modal
      // Prevents state values from persisting (like an onClose function) after their execution
      resetNotification();
    } else if (!!customBtnFunc && val) {
      customBtnFunc()
      notificationState.onClose(val, reason);
      // Cleanup state when we are done with the modal
      // Prevents state values from persisting (like an onClose function) after their execution
      resetNotification();
    } else {
      if (reason && reason === NotificationReason.BackdropClick) {
        return;
      } else {
        notificationState.onClose(val, reason);
      };
      // Cleanup state when we are done with the modal
      // Prevents state values from persisting (like an onClose function) after their execution
      resetNotification();
    }
  }
  return (
    <Modal
      open={open}
      onClose={() => handleClose(false, reason)}
      aria-describedby={'notification-title'}
      style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={paper}>
        <Grid container>
          <Grid item xs={12}>
            <Box sx={{ p: 4 }}>
              <Typography variant='h5'>
                <InfoIcon style={infoIconStyle}></InfoIcon><span id="notification-title"> {title}</span>
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider style={border} />
          </Grid>
          {message &&
            <Grid item xs={12}>
              <Box sx={{ px: 4, pt: 4 }}>
                {typeof message === 'string' ?
                  <Typography component='p'>
                    {message}
                  </Typography>
                  :
                  message
                }
              </Box>
            </Grid>
          }
          {component &&
            <Grid item xs={12}>
              <Box sx={{ px: 4, pt: 4, pb: 4 }}>
                <Typography component='p'>
                  {component}
                </Typography>
              </Box>
            </Grid>
          }
          <Grid item xs={12}>
            <Box sx={{ px: 4, pt: 4, pb: 4 }}>
              <Typography component='p'>
                {customComponent}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12}>
            <Divider style={border} />
          </Grid>
          <Grid item xs={12}>
            <Box sx={{ p: 4, display: 'flex', justifyContent: 'flex-end', flexDirection: 'row' }}>
              <Grid container spacing={1}>
                <Grid item>
                  <>{removeCancelBtn ? "" :
                    <Button variant='outlined' onClick={() => handleClose(false, reason)}>Cancel</Button>}</>
                </Grid>
                <Grid item>
                  <Button variant='contained' onClick={() => handleClose(true, reason)}> {customBtnText ? customBtnText : "Ok"}</Button>
                </Grid>
              </Grid>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </Modal>
  )
}
