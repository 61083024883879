export enum fundingCommitmentVisibilityEnum {
  STANDARD = 1,
  GRANTEE_RESTRICTED = 2,
  FULLY_RESTRICTED = 3
}

export enum FunderMemberAssociation {
  UMBRELLA_LEAD = 0,
  UMBRELLA_MEMBER = 1,
  COLLABORATIVE_MEMBER = 2,
  COLLABORATIVE_LEAD = 3,
  EVENT = 4,
  EVENT_MEMBER = 5
}

export enum FunderMemberType {
  FUNDER,
  USER
}

export enum ShowFunding {
  TRUE = 1,
  FALSE = 0,
}

export enum ShowEndorsed {
  TRUE = 1,
  FALSE = 0,
}

export enum FunderStatus {
  ACTIVE = 1,
  ARCHIVED = 2
}

export enum FunderServiceLevel {
  Individual = 1,
  Standard = 2,
  Institution = 3,
  FullService = 4,
  Enterprise = 5,
  "IndividualFind&Fund" = 6
}

export enum FunderNetworkVisibility {
  TRUE = 1,
  FALSE = 0,
}

export enum FunderProfileVisibility {
  TRUE = 1,
  FALSE = 0,
}

export enum FunderUserRole {
  PRIMARY_USER = 4,
  LOG_IN_AS = 2,
  MARK_FUNDING = 5,
  DISCOVER_PROPOSALS = 6,
  MANAGE_PROFILE = 1,
  GRANTS_MANAGEMENT = 8,
  MANAGE_USERS = 9,
  MANAGE_GRANTS = 10
}

export enum FundPriorities {
  CAPACITY_BUILDING = 0,
  RAPID_OR_URGENT = 1,
  MULTI_YEAR = 2,
}

export enum FundGrantCategories {
  GENERAL_OPERATING = 0,
  PROJECT_BASED = 1,
  RESTRICTED = 2
}

export enum FundDecisionStructure {
  PARTICIPATORY_MOVEMENT_LED = 0,
  DONOR_LED = 1,
  CROSS_CLASS = 2,
  OTHER = 3
}

export enum FundType {
  ALIGNED_FUND = 0,
  COLLABORATIVE_FUND = 1,
  POOLED_FUND = 2,
  GIVING_CIRCLE = 3,
  OTHER = 4
}

export enum CategoryOfFunding {
  "501C3" = 0,
  "501C4" = 1,
  FEDERALLY_RECOGNIZED_TRIBE = 2,
  INDIVIDUALS_SCHOLARSHIPS_FELLOWSHIPS = 3,
  INTERNATIONAL_ORGANIZATIONS = 4,
  CANDIDATES = 5,
  PACS = 6
}

export enum ProposalSummaryFrequency {
  NONE, 
  DAILY,
  WEEKLY
}