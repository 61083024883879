import { AxiosRequestConfig } from 'axios';
import { ICommitment, IGrantCycle, IPaginationDefaults } from '../../../interfaces';
import { ICreateFunder, IBloatedFunder, IFunderMembers, ILoginFunder } from '../../../interfaces/IFunder';
import { IPortalReport } from '../../../interfaces/IPortalReport.interface';
import { IUser, IFunderRole } from '../../../interfaces/IUser.interface';
import { api } from '../api.service';
import { IFunderChartStats } from '../../../interfaces/IFunderChartStats.interfaces';
import { IProfile } from '../../../interfaces/IProfile';

type BasicFunder = {
  id: number,
  name: string
};

interface IFundingInfo {
  commitments: ICommitment[],
  totalFunded: string,
};

export class FunderService {

  private static baseUrl = '/api/funders';
  // funders = fundersJSON.default as IOldFunder[];
  // grantCycles = grantCyclesJSON.default as IOldGrantCycle[];
  // constructor() {}

  // TODO change return type to IFunder
  //getFunderPermissions
  //will need to update in case Funder/User does not have a 'ref'
  public static async getFundersByEmail(userEmail: string): Promise<IBloatedFunder[]> {
    return new Promise(async (resolve, reject) => {
      return resolve([]);
    });
  }

  /**
   * ONLY USED FOR ADMIN
   * @param query Query parameters
   * @returns List of IFunders
   */
  public static async adminList(query = {}): Promise<IBloatedFunder[]> {
    const res = await api.get(`${this.baseUrl}`, { params: query });
    return res.data.rows as IBloatedFunder[]
  }

  public static async discover(query = {}): Promise<IBloatedFunder[]> {
    // We use the discover route for the portal
    const res = await api.get(`${this.baseUrl}/discover`, { params: query });
    return res.data.rows as IBloatedFunder[]
  }

  public static async listNameWithId(): Promise<BasicFunder[]> {
    const res = await api.get(`${this.baseUrl}/basic-funder-list`);
    return res.data.rows as IBloatedFunder[]
  }

  public static async listByName(query = {}): Promise<IBloatedFunder[]> {
    const res = await api.get(`${this.baseUrl}`, { params: query });
    return res.data.rows as IBloatedFunder[]
  }

  public static async getById(id: number, config?: AxiosRequestConfig): Promise<IBloatedFunder> {
    const res = await api.get(`${this.baseUrl}/${id}`, config);
    return res.data as IBloatedFunder;
  }

  public static async getContextFunder(id: number, config?: AxiosRequestConfig): Promise<ILoginFunder> {
    const res = await api.get(`${this.baseUrl}/context/${id}`, config);
    return res.data as ILoginFunder;
  }

  public static async getNetworks(): Promise<IUser[]> {
    const res = await api.get(`${this.baseUrl}/network`);
    return res.data as IUser[];
  }

  public static async getAdminNetworks(entityId: number): Promise<IUser[]> {
    const res = await api.get(`${this.baseUrl}/network-admin`, { params: { entityId: entityId } });
    return res.data as IUser[];
  }

  public static async getMembers(entityId: number): Promise<IFunderMembers[]> {
    const res = await api.get(`${this.baseUrl}/members`, { params: { entityId: entityId } })
    return res.data as IFunderMembers[];
  }

  public static async update(id: number, funder: any): Promise<IBloatedFunder> {
    const res = await api.put(`${this.baseUrl}/${id}`, funder);
    return res.data as IBloatedFunder;
  }

  public static async create(funder: { funder: ICreateFunder }): Promise<ICreateFunder> {
    const res = await api.post(`${this.baseUrl}/`, funder);
    return res.data as ICreateFunder;
  }

  public static async getCampaignById(id: number): Promise<IGrantCycle> {
    return new Promise(resolve => {
      resolve({} as any);
    })
  }

  public static async getFundingById(funder_id: number): Promise<IFundingInfo> {
    const res = await api.get(`/api/analyze/funder/${funder_id}`);
    return res.data as IFundingInfo;
  }

  public static async addFunderRole(funder_id: number, user_id: number, role_id: number): Promise<IFunderRole> {
    const res = await api.post(`${this.baseUrl}/${funder_id}/role/${user_id}`, { role_id });
    return res.data as IFunderRole;
  }

  public static async removeFunderRole(funder_id: number, user_id: number, role_id: number): Promise<boolean> {
    const res = await api.delete(`${this.baseUrl}/${funder_id}/role/${user_id}/${role_id}`);
    return res.data as boolean;
  }

  public static async portalReport(): Promise<IPortalReport> {
    const res = await api.get(`/pages/funder/portal-report`);
    return res.data as IPortalReport;
  }

  public static async getFundedTab(id: number, pagination?: IPaginationDefaults): Promise<IProfile[]> {
    let params: AxiosRequestConfig['params'] = {
      offset: pagination?.offset ?? 0,
      limit: pagination?.limit ?? 10
    };
    if(pagination?.sortBy) {
      params.sortBy = pagination.sortBy;
    }
    if(pagination?.sortDir) {
      params.sortDir = pagination.sortDir;
    }
    const res = await api.get(`/pages/funder/funded-tab/${id}`, { params });
    return res.data as IProfile[];
  }

  public static async getChartsStats(id: number): Promise<IFunderChartStats> {
    const res = await api.get(`${this.baseUrl}/${id}/chart-stats`);
    return res.data as IFunderChartStats;
  }

  public static async getSuggestedFunds(id: number): Promise<IProfile[]> {
    const res = await api.get(`${this.baseUrl}/${id}/suggested-funds`);
    return res.data as IProfile[]
  }

}
