import { useParams } from 'react-router-dom';
import { Box, Button, Divider, List, ListItem, Typography } from '@mui/material';
import { IRfp } from '../../interfaces/IRfp.interfaces';
import { Grid } from '@mui/material';
import { FetchPage } from '../consumers';
import DOMPurify from 'dompurify';
import { KeyDates } from './KeyDates';
import { ISurveyQuestion } from '../../interfaces/ISurvey.interfaces';
import { RFPStatus } from '../../enums/rfp-status';
import { Markdown } from '../legacy-ui';
import ApplicationSpecificQuestions from './RfpSections/ApplicationSpecificQuestions';

export interface IParams {
  id: string;
}

const classes = {
  fontSizeFix: {
    fontSize: 18
  },
  boxPadding: {
    m: '20px 4px'
  },
  applyBox: {
    marginTop: '2em',
    border: '5px solid #1A75BD',
    borderRadius: '5px',
    padding: '2em'
  },
  infoBox: {
    marginTop: '1em',
    marginBottom: '1em',
    borderRadius: '5px',
    backgroundColor: '#5D5F73',
    color: '#ffffff',
    padding: '1em'
  },
  warning: {
    backgroundColor: '#ffd2da',
    margin: 'auto',
    width: '85%',
    marginBottom: '3rem'
  },
  bold: {
    fontWeight: 'bold',
    textAlign: 'left',
    paddingLeft: '3rem',
    paddingTop: '1rem'
  },
  alignLeft: {
    textAlign: 'left',
    paddingLeft: '3rem'
  },
  previewTab: {
    marginTop: '10px',
    backgroundColor: '#1A75BD',
    width: '150px',
    textAlign: 'center',
    fontWeight: 'bold',
    color: '#fff',
    position: 'absolute'
  },
  commonQuestionsStyle: {
    fontWeight: '700',
    fontSize: '18px'
  }
};

// TODO - condense this with the non-public RFP page. Will need to update query based on URL.
export const PublicRfp = () => {
  const { id } = useParams<{ id: string }>();
  return (
    <FetchPage url={`/api/public/rfp/${id}`} renderSuccess={(data) => <DisplayRfp rfp={data} />} />
  );
};

export const DisplayRfp = ({ rfp }: { rfp: IRfp }) => {
  const logo = rfp?.funder?.logo;
  //@ts-ignore
  const surveyQuestions: ISurveyQuestion[] | null = rfp.surveyQuestions;
  // DOMPurify will remove target=blank because potential security risk.. need to re-add it manually.
  const aboutRfp = rfp?.about ? DOMPurify.sanitize(rfp?.about) : '';
  const application = rfp?.application
    ? DOMPurify.sanitize(rfp?.application, { ADD_ATTR: ['target'] })
    : '';
  const eligibility = rfp?.eligibility ? DOMPurify.sanitize(rfp?.eligibility) : '';
  const grantAwardRange = rfp?.grant_award_range ? DOMPurify.sanitize(rfp?.grant_award_range) : '';
  const attachments =
    rfp?.requiredResources && rfp.requiredResources.length > 0
      ? rfp.requiredResources.filter((resource) => resource.description !== '')
      : null;
  const contactEmail = rfp?.rfpContact?.find(
    (contact) => contact.email !== '' && contact.email !== null
  );
  const contactFirstName = rfp?.rfpContact?.find(
    (contact) => contact.first_name !== '' && contact.first_name !== null
  );
  const contactLastName = rfp?.rfpContact?.find(
    (contact) => contact.last_name !== '' && contact.last_name !== null
  );
  const validUrlTest = new RegExp(/https?:\/\//);
  const currentDate = new Date();
  const rfpDeadlineDate = new Date(rfp.deadline_date);
  const rfpAnnouncementDate = new Date(rfp.announcement_date);

  const rfpDateMessage = (): JSX.Element | void => {
    if (currentDate > rfpDeadlineDate) {
      return (
        <Typography>
          Thank you for your interest. This opportunity is now closed. Please contact{' '}
          <Box
            component="a"
            sx={{ color: '#ffffff' }}
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:info@justfund.us`}
          >
            info@justfund.us
          </Box>{' '}
          or{' '}
          <Box
            component="a"
            sx={{ color: '#ffffff' }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://justfund.us/sign-up"
          >
            sign up
          </Box>{' '}
          for JustFund to see more opportunities.
        </Typography>
      );
    }
    if (currentDate < rfpAnnouncementDate || rfp.status !== RFPStatus.APPROVED) {
      return (
        <Typography>
          This opportunity is not yet open. Please contact{' '}
          <Box
            component="a"
            sx={{ color: '#ffffff' }}
            target="_blank"
            rel="noopener noreferrer"
            href={`mailto:info@justfund.us`}
          >
            info@justfund.us
          </Box>{' '}
          or{' '}
          <Box
            component="a"
            sx={{ color: '#ffffff' }}
            target="_blank"
            rel="noopener noreferrer"
            href="https://justfund.us/sign-up"
          >
            sign up
          </Box>{' '}
          for JustFund to see more opportunities.
        </Typography>
      );
    }
    // If legacy data has dates that are super messed up, we don't want to display both
    if (currentDate < rfpAnnouncementDate && currentDate > rfpDeadlineDate) return;
    return;
  };

  return (
    <Box>
      <Box maxWidth="1024px" m="0 auto" sx={{ paddingTop: '30px' }}>
        {rfp ? (
          <>
            {!!logo ? (
              <Box width="100%" bgcolor={!!logo ? '#ffffff' : ''} textAlign="center">
                <Box
                  component="img"
                  src={logo?.url ? logo.url : '/images/placeholder.png'}
                  alt={logo?.name ? logo.name : 'placeholder image'}
                  height="140px"
                  style={{ objectFit: 'contain' }}
                />
              </Box>
            ) : (
              ''
            )}
            <Box sx={classes.boxPadding} textAlign="center">
              <Typography variant="h1">{rfp.name}</Typography>
            </Box>
          </>
        ) : (
          <Typography paragraph>Funding Opportunity not found.</Typography>
        )}
        <Divider />
        <Box sx={classes.boxPadding}>
          {(currentDate > rfpDeadlineDate ||
            currentDate < rfpAnnouncementDate ||
            rfp.status !== RFPStatus.APPROVED) && (
            <Box textAlign="left" sx={classes.infoBox}>
              {rfpDateMessage()}
            </Box>
          )}
          {rfp?.page ? (
            <Markdown source={rfp?.page} />
          ) : (
            <Grid container spacing={3}>
              {rfp?.about && (
                <Grid item sm={12}>
                  <Typography variant="h2">About</Typography>
                  <Box sx={classes.fontSizeFix} dangerouslySetInnerHTML={{ __html: aboutRfp }} />
                </Grid>
              )}
              {rfp?.eligibility && (
                <Grid item sm={12}>
                  <Typography variant="h2">Eligibility</Typography>
                  <Box sx={classes.fontSizeFix} dangerouslySetInnerHTML={{ __html: eligibility }} />
                </Grid>
              )}
              {rfp?.grant_award_range && (
                <Grid item sm={12}>
                  <Typography variant="h2">Grant Award Range</Typography>
                  <Box
                    sx={classes.fontSizeFix}
                    dangerouslySetInnerHTML={{ __html: grantAwardRange }}
                  />
                </Grid>
              )}
              <Grid item sm={12}>
                <Typography variant="h2">Key Dates</Typography>
                <KeyDates rfp={rfp} />
              </Grid>
              {rfp?.application && (
                <Grid item sm={12}>
                  <Typography variant="h2">Application</Typography>
                  <Box sx={classes.fontSizeFix} dangerouslySetInnerHTML={{ __html: application }} />
                </Grid>
              )}
              {surveyQuestions && <ApplicationSpecificQuestions surveyQuestions={surveyQuestions} />}
              {attachments && (
                <Grid item sm={12}>
                  <Typography variant="h3">Application Specific Attachments</Typography>
                  <Box sx={classes.fontSizeFix}>
                    <List sx={{ listStyleType: 'disc', pl: 4 }}>
                      {attachments.map((resource) => (
                        <ListItem sx={{ display: 'list-item' }} key={resource.id}>
                          {resource.description}
                        </ListItem>
                      ))}
                    </List>
                  </Box>
                </Grid>
              )}
              {(contactEmail ||
                rfp?.website ||
                rfp?.facebook ||
                rfp?.twitter ||
                rfp?.instagram) && (
                <Grid item sm={12}>
                  <Typography variant="h2">Contact Information</Typography>
                  {(contactFirstName || contactLastName) && (
                    <Grid item sm={12}>
                      <Typography variant="body2">
                        <strong>Name:</strong> {contactFirstName?.first_name}{' '}
                        {contactLastName?.last_name}
                      </Typography>
                    </Grid>
                  )}
                  {contactEmail && (
                    <Grid item sm={12}>
                      <Typography variant="body2">
                        <strong>Email:</strong>{' '}
                        <a href={`mailto:${contactEmail?.email}`}>{contactEmail?.email}</a>
                      </Typography>
                    </Grid>
                  )}
                  {rfp?.website && (
                    <Grid item sm={12}>
                      {validUrlTest.test(rfp?.website) ? (
                        <Typography variant="body2">
                          <strong>Website:</strong>{' '}
                          <a target="_blank" rel="noopener noreferrer" href={rfp?.website}>
                            {rfp?.website}
                          </a>
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          <strong>Website:</strong>{' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://${rfp?.website}`}
                          >
                            https://{rfp?.website}
                          </a>
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {rfp?.facebook && (
                    <Grid item sm={12}>
                      {validUrlTest.test(rfp?.facebook) ? (
                        <Typography variant="body2">
                          <strong>Facebook:</strong>{' '}
                          <a target="_blank" rel="noopener noreferrer" href={rfp?.facebook}>
                            {rfp?.facebook}
                          </a>
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          <strong>Facebook:</strong>{' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://${rfp?.facebook}`}
                          >
                            https://{rfp?.facebook}
                          </a>
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {rfp?.twitter && (
                    <Grid item sm={12}>
                      {validUrlTest.test(rfp?.twitter) ? (
                        <Typography variant="body2">
                          <strong>Twitter:</strong>{' '}
                          <a target="_blank" rel="noopener noreferrer" href={rfp?.twitter}>
                            {rfp?.twitter}
                          </a>
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          <strong>Twitter:</strong>{' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://${rfp?.twitter}`}
                          >
                            https://{rfp?.twitter}
                          </a>
                        </Typography>
                      )}
                    </Grid>
                  )}
                  {rfp?.instagram && (
                    <Grid item sm={12}>
                      {validUrlTest.test(rfp?.instagram) ? (
                        <Typography variant="body2">
                          <strong>Instagram:</strong>{' '}
                          <a target="_blank" rel="noopener noreferrer" href={rfp?.instagram}>
                            {rfp?.instagram}
                          </a>
                        </Typography>
                      ) : (
                        <Typography variant="body2">
                          <strong>Instagram:</strong>{' '}
                          <a
                            target="_blank"
                            rel="noopener noreferrer"
                            href={`https://${rfp?.instagram}`}
                          >
                            https://{rfp?.instagram}
                          </a>
                        </Typography>
                      )}
                    </Grid>
                  )}
                </Grid>
              )}
            </Grid>
          )}
          {currentDate < rfpDeadlineDate &&
            currentDate > rfpAnnouncementDate &&
            rfp.status === RFPStatus.APPROVED && (
              <Grid container>
                <Grid item xs={12}>
                  <Box textAlign="center" sx={classes.applyBox}>
                    <Typography variant="h4" gutterBottom>
                      How To Apply
                    </Typography>
                    <Typography paragraph gutterBottom>
                      Sign up for JustFund to apply for this opportunity.
                    </Typography>
                    <Button
                      variant="contained"
                      type="button"
                      onClick={() => window.open('https://justfund.us/sign-up', '_blank')}
                      sx={{ marginBottom: '1em' }}
                    >
                      Sign Up For JustFund
                    </Button>
                    <Typography paragraph>
                      <a
                        target="_blank"
                        rel="noopener noreferrer"
                        href={`${
                          window.location.origin
                        }/justfund/signin?redirect=${encodeURIComponent(`/o/rfps/${rfp.id}`)}`}
                      >
                        Sign in to your account
                      </a>
                    </Typography>
                  </Box>
                </Grid>
              </Grid>
            )}
        </Box>
      </Box>
    </Box>
  );
};
