import { TextField, TextFieldProps } from '@mui/material';

const JFReadOnlyTextField = (props: TextFieldProps) => {
  const {
    multiline = true,
    variant = 'standard',
    fullWidth = true,
    InputProps = {
      disableUnderline: true,
      style: { fontSize: '16px' }
    }
  } = props;

  return (
    <TextField
      variant={variant}
      fullWidth={fullWidth}
      multiline={multiline}
      {...props}
      InputProps={{
        ...InputProps,
        readOnly: true
      }}
    />
  );
};

export default JFReadOnlyTextField;
