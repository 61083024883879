import React, { createContext, useCallback, useContext, useEffect, useReducer, useState } from 'react';
import { ILoginFunder } from '../../shared/interfaces/IFunder';
import { IUserAssociations } from '../../shared/interfaces/IUserAssociations.interface';
import { IUser } from '../../shared/interfaces/IUser.interface';
import { FunderService } from '../../shared/services/api-service/funder-api.service';
import { UserAuthService } from '../../shared/services/api-service/user-auth-api.service';
import { UserService } from '../../shared/services/api-service/user-api.service';
import { FunderServiceLevel, FunderUserRole } from '../../shared/enums/funder/funder-enums';
import { BookmarkService } from '../../shared/services/api-service/bookmark.service';
import { BookmarkEntityType } from '../../shared/enums/bookmark.enum';
import { hotjarService } from '../../shared/services/hotjar-service/hotjar.service';
import { UserEntityType } from '../../shared/enums/user.enum';
import { useCancellableEffect } from '../../shared/hooks/useCancellableEffect';
import { HubspotService } from '../../shared/services';
import { FollowsProvider } from '../../shared/providers/follows.provider';
import RealtimeProvider from '../../shared/providers/realtime.provider';

type UserContextProvider = {
  associations: IUserAssociations | null;
  selectFunder: (id: number) => void;
  funder: ILoginFunder | null;
  user: IUser | null;
  markFundingPermissions: () => boolean;
  discoverProposalsPermissions: () => boolean;
  bookmarkCount: number;
  hasRole: (funderUserRole: FunderUserRole) => boolean;
  refreshBookmarkCount: () => void;
  isScoreManager: () => boolean,
  hasScoringPermissions: () => boolean,
  isIndividualFindFund: boolean
}

const FunderContext = createContext<UserContextProvider>({
  associations: null,
  selectFunder: () => { },
  funder: null,
  user: null,
  markFundingPermissions: () => false,
  discoverProposalsPermissions: () => false,
  bookmarkCount: 0,
  hasRole: () => false,
  refreshBookmarkCount: () => {},
  // setFunder: null,
  isScoreManager: () => false,
  hasScoringPermissions: () => false,
  isIndividualFindFund: false
});

export const useFunderContext = () => useContext(FunderContext);

export default function FunderProvider({ children }: { children: React.ReactNode }) {
  const [user, setUser] = useState({} as IUser);
  const [loading, setLoading] = useState(true);
  const [bookmarkCount, setBookmarkCount] = useState(0);
  const [funder, setFunder] = useState({} as ILoginFunder);
  const [associations, setAssociations] = useState<IUserAssociations | null>(null);
  const [bookmarkChange, refreshBookmarkCount] = useReducer((state) => {
    return state + 1;
  }, 0);

  const initializeContext = useCallback(() => {
    const { entityType, entityId } = UserAuthService.getEntity();
    if (entityType && parseInt(entityType, 10) === UserEntityType.FUNDER) {
      selectFunder(parseInt(entityId!, 10));
    }
    else {
      console.log('logging out');
      UserAuthService.logout(true);
      return;
    }
  }, []);

  const selectFunder = async (id: number) => {

    const newFunder = await FunderService.getContextFunder(id);
    setFunder(newFunder);
    // this is a bandaid. Should really run through our context tree(s) with a fine toothed comb, and see what fat can be cut. 
    // We call context a lot uncessarily, especially this 'verifyUser' function, which by side effect calls selectFunder.
    // Removing this re-route for now, maybe added later as a TODO 
    // if (newFunder.invitedEvents.length > 0 && !location.pathname.includes('/f/proposals')) {
    //   history.push('/f/events');
    // }
    return;
  }
  
  const isScoreManager = () => funder?.scoring_enabled && hasRole(FunderUserRole.MARK_FUNDING);

  const hasScoringPermissions = () =>
    funder?.scoring_enabled &&
    (hasRole(FunderUserRole.MARK_FUNDING) || user?.hasScoring);

  const hasRole = (funderUserRole: FunderUserRole): boolean => {
    return user?.funderRoles?.some(role => role.role_id === funderUserRole && role.funder_id === funder?.id) || false;
  }

  const isIndividualFindFund = funder?.service_level === FunderServiceLevel['IndividualFind&Fund'];

  /**
   * @deprecated
   * @returns
   */
  const markFundingPermissions = () => {
    const markFunding = user?.funderRoles?.filter(r => r.funder_id === funder?.id);
    return markFunding ? markFunding.some(r => r.role_id === FunderUserRole.MARK_FUNDING) : false;
  };

  /**
   * @deprecated
   * @returns
   */


  // TODO - this isn't used anywhere yet, need API update to integrate Discover Proposals permissions.
  /**
   * @deprecated
   * @returns
   */
  const discoverProposalsPermissions = () => {
    const discoverProposals = user?.funderRoles?.filter(r => r.funder_id === funder?.id);
    return discoverProposals ? discoverProposals.some(r => r.role_id === FunderUserRole.DISCOVER_PROPOSALS) : false;
  }

  useEffect(() => {
    UserAuthService.verifyLogin().then(async loginVerification => {
      const userId = loginVerification.user.id;
      hotjarService.userId = userId;
      HubspotService.identifyUser(loginVerification.user?.email!);
      const associationsResponse = UserAuthService.getAssociations(userId).then(userAssociations => {
        setAssociations(userAssociations);
        hotjarService.hasGranteeAssociations = !!userAssociations?.grantees && userAssociations.grantees.length > 0;
        hotjarService.hasFunderAssociations = !!userAssociations?.funders && userAssociations.funders.length > 0;
      });
      const userResponse = UserService.getById(userId).then(jfUser => {
        setUser(jfUser)
        hotjarService.userEmail = jfUser.email!;
      });
      Promise.all([associationsResponse, userResponse]).then(() => setLoading(false));
    });
  }, []);

  useEffect(() => {
    initializeContext();
  }, [initializeContext]);

  useCancellableEffect(signal => {
    BookmarkService.getCount({ params: { bookmarkTypes: [BookmarkEntityType.FUNDER, BookmarkEntityType.GRANTEE, BookmarkEntityType.PROPOSAL, BookmarkEntityType.COMMON_APPLICATION ] }, signal }).then((result) => {
      setBookmarkCount(result.count)
    });
  }, [bookmarkChange]);

  return (
    <FunderContext.Provider
      value={{
        associations,
        selectFunder,
        user: user,
        funder: funder,
        markFundingPermissions,
        discoverProposalsPermissions,
        bookmarkCount,
        refreshBookmarkCount,
        hasRole,
        isScoreManager,
        hasScoringPermissions,
        isIndividualFindFund
      }}
    >
      <RealtimeProvider user={user}>
        <FollowsProvider user={user}>
          { !loading ? children : null }
        </FollowsProvider>
      </RealtimeProvider>
    </FunderContext.Provider>
  )
}
